<div id="db-wrapper" [ngClass]="{ 'toggled' : isSidebarMenuToggled}">
  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <main id="page-content">
    <!-- Header -->
    <app-header (toggleSidebarEvent)="setSidebarMenuToggled($event)"></app-header>
    <!-- Container fluid -->
    <section class="container-fluid p-4">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <!-- Page Header -->
          <div class="border-bottom pb-3 mb-3 d-md-flex align-items-center justify-content-between">
            <div class="mb-3 mb-md-0">
              <h1 class="mb-1 h2 fw-bold">My Tests</h1>
              <!-- Breadcrumb -->
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">My Tests</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    All
                  </li>
                </ol>
              </nav>
            </div>
            <div class="d-flex gap-2">
              <a (click)="openQuizGenerationDialog()" class="btn btn-primary">Generate Quiz</a>
              <a (click)="cleanLocalStorageAndNavigate()" class="btn btn-primary">Add a Test</a>
            </div>
          </div>
        </div>
      </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <!-- Card -->
          <div class="card rounded-3">
            <!-- Card header -->
            <div class="p-4 row">
              <!-- Form -->
              <form class="d-flex align-items-center col-12 col-md-12 col-lg-12">
                <span class="position-absolute ps-3 search-icon"><i class="fe fe-search"></i></span>
                <input type="search" class="form-control ps-6" #searchInput
                       (input)="onSearch(searchInput.value,  1,  10)"
                       (keyup.enter)="onEnterKeyPressed(searchInput.value)" placeholder="Search Test">
              </form>

              <div id="searchResults">
                <div *ngIf="quizzes.length === 0 && searchTerm.trim() != ''">There are no search results.</div>
              </div>
            </div>


            <div>
              <!-- Table -->
              <div class="tab-content" id="tabContent">
                <!--Tab pane -->
                <div class="tab-pane fade show active" id="courses" role="tabpanel" aria-labelledby="courses-tab">
                  <div class="table-responsive border-0 overflow-y-hidden">
                    <table class="table mb-0 text-nowrap table-centered table-hover">
                      <thead class="table-light">
                      <tr>
                        <th scope="col">
                          Tests
                        </th>
                        <th scope="col">
                          Category
                        </th>
                        <th scope="col">
                          Status
                        </th>
                        <th scope="col">
                          Date
                        </th>
                        <th scope="col">Description</th>
                        <th scope="col">Total results</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let quiz of quizzes, let page = index" [routerLink]="['/quiz/add']"
                          [queryParams]="{id : quiz.id}" class="cursor-pointer">
                        <td>
                          <a class="text-inherit" [routerLink]="['/quiz/add']"
                             [queryParams]="{id : quiz.id}">
                            <div class="d-flex align-items-center">
                              <div>
                                <img *ngIf="quiz.logoUpload; else defaultLogo"
                                     [src]="apiUrl+'/image?picName='+quiz.logoUpload"
                                     class="img-preview">
                                <ng-template #defaultLogo>
                                  <img src="/assets/default-ui-image-placeholder-wireframes-600nw-1037719192.webp"
                                       alt=""
                                       class="img-preview">
                                </ng-template>
                              </div>
                              <div class="ms-3 ">
                                <h4 class="mb-1 text-primary-hover text-normal">
                                  {{ quiz.name }}
                                </h4>
                              </div>
                            </div>
                          </a>
                        </td>
                        <td *ngIf="quiz.category">
                          <div class="d-flex align-items-center">
                            <h5 class="mb-0 unset-font-weight">{{ quiz.category }}</h5>
                          </div>
                        </td>
                        <td>
                          <div class="mdc-drawer__status-tag status-color"
                               [ngClass]="{'t_violet': quiz.status === QuizStatus.SETUP_IN_PROGRESS,
                                           't_green': quiz.status === QuizStatus.ACTIVE,
                                           't_gray': quiz.status === QuizStatus.ENDED}">
                            {{ getStatus(quiz.status.toString()) }}
                          </div>
                        </td>
                        <td>
                          <span>{{ quiz.createdDate | date }}</span>
                        </td>
                        <td class="with">
                          <span [innerHTML]="getSanitizedHTML(quiz.description)"></span>
                        </td>
                        <td>
                          <span>{{ quiz.totalResult === 0 ? '' : quiz.totalResult }}</span>
                        </td>
                        <td>
                          <span class="dropdown dropstart" (click)="$event.stopPropagation()">
                            <button class="btn-icon btn btn-ghost btn-sm rounded-circle"
                                    [matMenuTriggerFor]="beforeMenu" aria-label="Open menu">
                            <i class="fe fe-more-vertical vertical-font-size"></i>
                            </button>
                            <span class="dropdown-menu">
                              <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <button class="dropdown-item"
                                        (click)="openDuplicateDialog(quiz?.name, quiz?.id)">Duplicate</button>
                                <button class="dropdown-item" (click)="openDeleteDialog(quiz?.id)"
                                        *ngIf="!canDelete(quiz.status)">Delete</button>
                                <button class="dropdown-item" [routerLink]="['/quiz/add']"
                                        [queryParams]="{id : quiz.id}" *ngIf="!canEdit(quiz.status)">Edit</button>
                              </mat-menu>
                            </span>
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card Footer -->
            <div class="card-footer" *ngIf="this.quizzesTotalCount > this.pageSize">
              <!-- Pagination section -->
              <nav aria-label="Page navigation example">
                <ul class="pagination mb-0">
                  <li class="page-item" (click)="previousPage()" [class.disabled]="pageNumber === 1">
                    <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-left"></i></a>
                  </li>
                  <li class="page-item"
                      (click)="goToPage(pageNumber)">
                    <a class="page-link mx-1 rounded">{{ pageNumber }}</a>
                  </li>
                  <li class="page-item" (click)="nextPage()" [class.disabled]="quizzes.length < pageSize">
                    <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>





