import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from 'src/environments/environment';
import {Question} from "../models/Question";
import {AnswerOption} from "../models/AnswerOption";
import {GeneralRequestModel} from "../models/generalRequestModel";
import {QuestionsDataModel} from "../models/QuestionsDataModel";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) {
  }

  saveQuestion(question: Question): Observable<any> {
    let headers = this.createHeaders();
    return this.http.post(`${this.apiUrl}/questions`, question, {headers})
  }

  editQuestion(question: Question): Observable<any> {
    let headers = this.createHeaders();
    return this.http.put(`${this.apiUrl}/questions/${question.id}`, question, {headers})
  }

  updateQuestionSerialNumber(id: string, serialNumber: number): Observable<number> {
    const url = `${this.apiUrl}/questions/${id}/serialNumber`;
    const headers = this.createHeaders();
    return this.http.request<number>('patch', url, {
      headers: headers,
      body: serialNumber
    });
  }

  findAllByQuizId(quizId: string | undefined, paginationGeneralRequest: GeneralRequestModel): Observable<QuestionsDataModel> {
    let headers: HttpHeaders = this.createHeaders();
    let params = new HttpParams()
      .set('page', paginationGeneralRequest.page)
      .set('count', paginationGeneralRequest.count)
      .set('sortDirection', "asc")
    const url = `${this.apiUrl}/questions/${quizId}`;
    return this.http.get<QuestionsDataModel>(url, {params, headers});
  }

  totalCount(quizId: string | undefined): Observable<number> {
    const url = `${this.apiUrl}/questions/count/${quizId}`;
    return this.http.get<number>(url);
  }

  findAnswersByQuestionId(questionId: string): Observable<AnswerOption[]> {
    let headers = this.createHeaders();
    const url = `${this.apiUrl}/answers/${questionId}`;
    return this.http.get<AnswerOption[]>(url, {headers});
  }

  private createHeaders(): HttpHeaders {
    const token = localStorage.getItem('fb-token');
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  deleteQuestions(questionIds: string[]): Observable<string[]> {
    let headers = this.createHeaders();
    return this.http.request<string[]>('delete', `${this.apiUrl}/questions/delete`, {
      headers: headers,
      body: questionIds
    });
  }
}
