import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild,} from '@angular/core';
import {ResultModel} from "../../models/resultModel";
import {ActivatedRoute} from "@angular/router";
import {ProgressSpinnerMode} from "@angular/material/progress-spinner";
import {QuestionType} from "../../models/QuestionType";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {DateUtil} from "../../util/date.util";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AngularEditorConfigsUtil} from "../../util/angular-editor-configs.util";
import {DescriptiveQuestionFeedBack} from "../../models/DescriptiveQuestionFeedBack";
import {ResultService} from "../../services/result.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AnswerOption} from "../../models/AnswerOption";
import {RespondentService} from "../../services/respondent.service";
import {QuestionAnswerNameModel} from "../../models/questionAnswerNameModel";
import {finalize} from "rxjs";

@Component({
  selector: 'app-respondent-result',
  templateUrl: './respondent-result.component.html',
  styleUrls: ['./respondent-result.component.css']
})

export class RespondentResultComponent implements OnInit {
  quizId!: string;
  respondentId?: string;
  isLoading: boolean = false;
  result!: ResultModel;
  mode: ProgressSpinnerMode = 'determinate';
  protected readonly QuestionType = QuestionType;
  startTimeLocal!: Date | undefined;
  endTimeLocal!: Date | undefined;
  editorContent: string = '';
  questionId!: string;
  score: number = 0;
  adminId!: string;
  @ViewChild('closebutton') closeButton!: ElementRef;
  configNoInsertImage: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons
    ]
  }
  questionScore!: number;
  questionAnswers!: QuestionAnswerNameModel[];

  constructor(private respondentService: RespondentService,
              private jwtHelper: JwtHelperService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private resultService: ResultService,
              private cdRef: ChangeDetectorRef) {
    this.route.queryParams.subscribe(queryParams => {
      const respondentId = queryParams['respondentId'];
      if (respondentId) {
        this.respondentId = respondentId;
        const quizId = queryParams['quizId'];
        if (quizId) {
          this.quizId = quizId;
        }
      }
    })
  }

  ngOnInit() {
    this.route.params.subscribe(queryParams => {
      const quizId = queryParams['quizId'];
      const respondentId = queryParams['respondentId'];
      if (quizId && respondentId) {
        this.resultService.getTestResult(quizId, respondentId).subscribe({
          next: (result: ResultModel) => {
            if (result) {
              this.result = result;
              this.startTimeLocal = DateUtil.convertUTCtoLocal(this.result.startTime);
              this.endTimeLocal = DateUtil.convertUTCtoLocal(this.result.endTime);
              this.cdRef.detectChanges();
            }
          }
        })
      }
    });
    this.getResult();
  }

  getResult() {
    this.isLoading = true;
    this.cdRef.detectChanges();

    this.respondentService.saveResultAndGet(this.quizId, this.respondentId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cdRef.detectChanges();
        })
      )
      .subscribe({
        next: (result: ResultModel) => {
          if (result) {
            this.result = result;
            this.startTimeLocal = DateUtil.convertUTCtoLocal(this.result.startTime);
            this.endTimeLocal = DateUtil.convertUTCtoLocal(this.result.endTime);
            this.cdRef.detectChanges();
          }
        }
      });
  }

  getSanitizedHTML(htmlContent: string | undefined): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(<string>htmlContent);
  }

  calculateProgress(timeSpent: number | undefined, quizDuration: number | undefined): number {
    if (!quizDuration) {
      return 100;
    }
    return (<number>timeSpent / <number>quizDuration) * 100;
  }

  getScoreRange(maxScore: number): number[] {
    return Array.from({length: maxScore + 1}, (_, index) => index);
  }

  addFeedback(question: QuestionAnswerNameModel) {
    this.questionId = question.id;
    this.score = Number(question.descriptiveScore);
    this.editorContent = question.descriptiveFeedBack;
  }

  getAdminId() {
    const token = localStorage.getItem('fb-token');
    if (token) {
      const decodeToken = this.jwtHelper.decodeToken(token);
      return !!(decodeToken && decodeToken.userId === this.result?.adminId);
    } else {
      return false;
    }
  }

  respondentScore(id: string): number {
    const questionData = this.result?.questionAnswerNameDtoList.find(item => item.id === id);
    if (!questionData) {
      return 0;
    }
    const respondentCorrectAnswersIds = questionData.respondentAnswers && questionData.respondentAnswers
      .filter(answer => answer.isCorrectAnswer)
      .map(respondentAnswer => respondentAnswer.id) || [];
    const correctAnswers = questionData.answers.filter(answer => answer.isCorrectAnswer).map(answer => answer.id);
    return correctAnswers.every(answer => respondentCorrectAnswersIds.includes(answer)) && correctAnswers.length === questionData.respondentAnswers.length ? questionData.questionScore : 0;
  }

  shortAnswerScore(question: QuestionAnswerNameModel): number {
    this.score = 0;
    this.route.queryParams.subscribe(queryParams => {
      if (this.result && this.result.questionAnswerNameDtoList) {
        const questionAnswerNameModel = this.getQuestionAnswerNameModel(this.result, question.id);
        if (questionAnswerNameModel && questionAnswerNameModel.shortAnswers) {
          this.questionScore = questionAnswerNameModel.questionScore;
          questionAnswerNameModel.answers.forEach((answer: AnswerOption) => {
            this.questionAnswers = this.result.questionAnswerNameDtoList;
            this.getShortAnswerQuestionScore(question, answer);
          });
        }
      }
    });
    return this.score;
  }

  getShortAnswerQuestionScore(question: QuestionAnswerNameModel, answer: AnswerOption) {
    if (this.questionAnswers) {
      this.questionAnswers.some((questionAnswer: QuestionAnswerNameModel) => {
          this.score += questionAnswer?.shortAnswers.some((shortAnswer: string) => {
            return question.isCapitalized
              ? shortAnswer === answer.variantName
              : shortAnswer.toLowerCase() === answer.variantName.toLowerCase();
          }) && this.score < this.questionScore
            ? this.questionScore
            : 0;
        });
    }
  }

  descriptiveScore(id: string) {
    let score = 0;
    if (this.result) {
      const questionAnswerNameModel = this.getQuestionAnswerNameModel(this.result, id);
      if (questionAnswerNameModel && questionAnswerNameModel.descriptiveScore) {
        score = Number(questionAnswerNameModel.descriptiveScore);
      }
    }
    return score;
  }

  selectedScore(question: QuestionAnswerNameModel) {
    this.setDescriptiveFeedbackAndScore(question.id, Number(question.descriptiveScore), question.descriptiveFeedBack || '');
  }

  saveFeedback() {
    this.setDescriptiveFeedbackAndScore(this.questionId, this.score, this.editorContent);
  }

  setDescriptiveFeedbackAndScore(id: string, score: number, feedback: string) {
    let newFeedback: DescriptiveQuestionFeedBack = {
      quizId: this.result?.quizId || this.quizId,
      questionId: id,
      respondentId: this.result?.respondentId || this.respondentId,
      score: score,
      feedback: feedback,
    };
    this.resultService.setFeedback(newFeedback).subscribe(() => {
      this.getResult();
      this.editorContent = '';
      this.closeButton.nativeElement.click();
    });
  }


  private getQuestionAnswerNameModel(result: ResultModel, id: string): QuestionAnswerNameModel | undefined {
    let foundModel: QuestionAnswerNameModel | undefined = undefined;
    result.questionAnswerNameDtoList.forEach((questionAnswerNameModel) => {
      if (id === questionAnswerNameModel.id) {
        foundModel = questionAnswerNameModel;
      }
    });
    return foundModel;
  }

}
